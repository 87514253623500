document.addEventListener("turbolinks:load", () => {
  var itemSelector = document.querySelector("#item-selector");
  var itemSelection = document.querySelector("#item-selection");
  var itemSelectorButton = document.querySelector("#item-selector-button");
  var itemParam = new URLSearchParams(window.location.search);
  if (itemSelector) {
    itemParam.has("items")
      ? (itemSelector.value = itemParam.get("items"))
      : (itemSelector.value = 15);
    var itemValue = itemSelector.value;

    if (itemValue < 5) {
      itemValue = 1;
      itemSelection.innerText = `Mostrar ${itemValue} item`;
      itemSelectorButton.href = `?items=${itemValue}`;
    } else if (itemValue > 100) {
      itemValue = 100;
      itemSelector.value = itemValue;
      itemSelectorButton.href = `?items=${itemValue}`;
    } else itemSelection.innerText = `Mostrar ${itemValue} itens`;

    itemSelector.value = itemValue;

    itemSelector.addEventListener("change", (el) => {
      itemValue = el.target.value;
      if (itemValue < 5) itemValue = 1;
      itemSelectorButton.href = `?items=${itemValue}`;
      itemSelectorButton.click();
    });
  }
});
